import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

/**
 * @function BrandIcon
 * @param props
 */
const BrandIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 34 34">
      <path d="M16.6855 20.1938H14.7869C13.9654 20.1938 13.2994 20.8598 13.2994 21.6813V26.3429C13.2994 27.1644 13.9654 27.8304 14.7869 27.8304H16.6855V20.1938Z" />
      <path d="M34 14.8432V31.5773C34 32.9165 32.9095 34 31.5698 34H21.4542C23.4086 30.032 25.2311 25.7075 25.1135 23.3425C25.1135 18.8627 21.4763 15.2311 16.9962 15.2311C12.517 15.2311 8.87881 18.8627 8.87881 23.3425C8.87881 23.3425 8.87165 23.4818 8.89332 23.7599V23.7671C8.89332 23.811 8.90099 23.8698 8.90099 23.928C8.92984 24.2871 8.97378 24.6385 9.04695 24.9898C9.33999 26.6589 10.1375 29.5564 12.3189 34H2.43015C1.0828 34 0 32.9165 0 31.5773V14.8797C0 14.1698 0.307544 13.496 0.849183 13.0347L15.4223 0.582369C16.3302 -0.194123 17.6698 -0.194123 18.5778 0.582369L33.1438 12.9909C33.6854 13.4521 34 14.1255 34 14.8432Z" />
      <path d="M19.3084 20.1938H17.4099V24.0835L19.527 23.7689C20.2561 23.6606 20.7959 23.0347 20.7959 22.2976V21.6813C20.7959 20.8598 20.1299 20.1938 19.3084 20.1938Z" />
      <path d="M19.4581 24.2908L17.4099 24.0835V27.8127H19.3084C20.1299 27.8127 20.7959 27.1467 20.7959 26.3252V25.7707C20.7959 25.0072 20.2178 24.3676 19.4581 24.2908Z" />
    </SvgIcon>
  );
};

export default BrandIcon;
