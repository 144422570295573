import type { VFC } from 'react';
import useScrollTrigger from '@mui/material/useScrollTrigger';

import Link from 'next/link';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

import { useAuth } from '@/context/authentication';

import BrandIcon from '@/components/Icons/BrandIcon';

import { N0, N800, G500 } from '@/styles/color';

import type { DesktopTopNavigationProps } from './types';
import { MUIAppBar, MUIToolbar } from './styles';

/**
 * @function DesktopTopNavigation
 * @params props
 */
const DesktopTopNavigation: VFC<DesktopTopNavigationProps> = props => {
  const { translucentBehaviour = false } = props;

  const { isAuth } = useAuth();
  const scrolled = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  const transparent = translucentBehaviour ? !scrolled : false;

  return (
    <MUIAppBar position="fixed" transparent={transparent} elevation={0}>
      <MUIToolbar transparent={transparent}>
        <Link href="/" passHref>
          <Typography
            variant="h2"
            component="a"
            noWrap
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexGrow: 1,
              color: transparent ? N0 : N800,
            }}
          >
            <BrandIcon sx={{ mr: 1, fontSize: '34px', color: transparent ? N0 : G500 }} />
            besthome
          </Typography>
        </Link>
        <Stack direction="row" spacing={3} alignItems="center">
          <Link href="/search" passHref>
            <Typography sx={{ color: transparent ? N0 : N800 }} variant="body2-bold" component="a">
              Properti Baru
            </Typography>
          </Link>
          <Link href="/search" passHref>
            <Typography sx={{ color: transparent ? N0 : N800 }} variant="body2-bold" component="a">
              KPR
            </Typography>
          </Link>
          {!isAuth ? (
            <Link href="/login" passHref>
              <Button variant="contained" size="large" disableElevation sx={{ padding: '14px 32px' }}>
                <Typography variant="body2-bold" component="span">
                  Masuk / Daftar
                </Typography>
              </Button>
            </Link>
          ) : null}
        </Stack>
      </MUIToolbar>
    </MUIAppBar>
  );
};

export default DesktopTopNavigation;
