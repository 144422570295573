/**
 * @function hexToRgba
 * @param {string} hex
 * @param {number} alpha
 * @returns
 */
const hexToRgba = (hex: string, _alpha?: number): string => {
  try {
    const alpha = _alpha || 1;
    const regex = /(.*)(#([a-f\d]{1,2})([a-f\d]{1,2})([a-f\d]{1,2}))(.*)/i;
    const exec = regex.exec(hex);
    const prefix = exec ? exec[1] : '';
    const postfix = exec ? exec[6] : '';
    const hexColor = exec ? exec[2] : '';

    let r = exec ? exec[3] : '';
    let g = exec ? exec[4] : '';
    let b = exec ? exec[5] : '';

    if (hexColor.length === 4) {
      r += r;
      g += g;
      b += b;
    }

    const red = parseInt(r, 16);
    const green = parseInt(g, 16);
    const blue = parseInt(b, 16);
    const rgbaColor = `rgba(${red}, ${green}, ${blue}, ${alpha})`;

    return `${prefix + rgbaColor + postfix}`;
  } catch (err) {
    console.error(`Wrong color format argument given. Please use appropriate hex color format.`, err);
    return 'rgba(255, 255, 255, 1)';
  }
};

export default hexToRgba;
