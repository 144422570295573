import { styled } from '@mui/material/styles';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';

import type { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import type { ToolbarProps as MuiToolbarProps } from '@mui/material/Toolbar';

import { N0, N100 } from '@/styles/color';
import { APP_BAR_HEIGHT } from '@/styles/spacing';
import hexToRgba from '@/styles/utils/hexToRgba';

interface AppBarProps extends MuiAppBarProps {
  transparent?: boolean;
}

interface ToolbarProps extends MuiToolbarProps {
  transparent?: boolean;
}

export const MUIAppBar = styled(AppBar, {
  shouldForwardProp: prop => prop !== 'transparent',
})<AppBarProps>(({ theme, transparent }) => ({
  '&.MuiAppBar-root': {
    backgroundColor: transparent ? 'transparent' : theme.palette.common.white,
    transition: 'background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
}));

export const MUIToolbar = styled(Toolbar, {
  shouldForwardProp: prop => prop !== 'transparent',
})<ToolbarProps>(({ transparent }) => ({
  '&.MuiToolbar-root ': {
    borderBottom: `1px solid ${transparent ? hexToRgba(N0, 0.24) : N100}`,
    height: `${APP_BAR_HEIGHT}px`,
    transition: 'border-bottom 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
}));
